(function() {
	
	/*
		take a look at: https://appelsiini.net/projects/lazyload/	
	*/
	function activateLazyload() {
		
		$("img.lazy").lazyload().removeClass("blur");
		picturefill({
			reevaluate: true,
			elements: $("img.lazy")
		 });
		 
	}
	
	jQuery(document).ready(activateLazyload);
	
})();