(function() {

	function hamburger() {

		var $icon = $('.menu-toggle');
		var $menu = $('.nav-overlay-wrapper');
		var $header = $('.header');
		var $body = $('body');

		$icon.on('click', function() {
			if ($menu.hasClass('is-visible')) {
				$menu.removeClass('is-visible');
				$icon.removeClass('is-active');
				$body.removeClass('overflow-hidden');
			} else {
				$menu.addClass('is-visible');
				$icon.addClass('is-active');
				$header.removeClass('is-visible is-fixed');
				$body.addClass('overflow-hidden');

				$('html, body').animate({
					scrollTop: 0
				}, 600);
			}
		});
	}
	jQuery(document).ready(hamburger);

	function menu() {
		var $header = $('.header');

		$(window).on('scroll', {
			previousTop: 0
		}, function() {
			var currentTop = $(window).scrollTop();
			//check scroll direction
			if (currentTop < this.previousTop) {
				//if scrolling up...
				if (currentTop > 0 && $header.hasClass('is-fixed')) {
					$header.addClass('is-visible');
				} else {
					$header.removeClass('is-visible is-fixed');
				}
			} else {
				//if scrolling down...
				$header.removeClass('is-visible');
				if (currentTop > $header.height() && !$header.hasClass('is-fixed'))
					$header.addClass('is-fixed');
			}
			this.previousTop = currentTop;
		});
	}
	jQuery(document).ready(menu);

})();
