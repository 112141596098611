const zigzag = function() {
	'use strict';
	const debug = true;
	const $zigzag = $('#zigzag');
	if ($zigzag.length === 0) return;
	if (debug) console.info('zigzag', new Date().toLocaleString());
	$('#zigzag > svg').remove();	// clean up after load/resize
	const isMobile = $(window).width() <= 480? true : false;

	// Adopted from stylesheet
	const headerPaddingY = $zigzag.position().top;	// Header area
	const baseWidth = 5;	// Grey line
	const baseCol = '#f30';
	const fillWidth = 5;	// Red line
	const fillCol = '#c5c6c8';
	const headingPaddingX = 8;	// Headings
	const headingPaddingY = 4;

	const lineStartFactor = .9	// Start of line, relative to header height
	const headingPosFactor = isMobile? .5 : .25;	// Position of heading on line
	const scrollFactor = .8;	// Scroll animation speed. Bigger number: slower
	const popClass = 'pop-in';
	const popupAfterLineCompleted = false;	// Wait for finished line?
	const popupOneByOne = false;	// Trigger one by one?
	const popupPermanent = true;	// Stay after popping up once?
	const popUpper = .25;	//	Bigger number: popup triggers earlier
	const popLower = .65;	// Bigger number: popup takes longer (only when popupOneByOne is true)

	// Containers and dimensions
	const $winHeight = $(window).innerHeight();
	const $docHeight = $(document).innerHeight();
	const $zigzagHeight = $zigzag.innerHeight();
	const $zigzagWidth = $zigzag.innerWidth();
	const $zigzagTop = $zigzag.offset().top;
	const $zigzagLeft = $zigzag.offset().left;
	const $headerEl = $('.case-module-header > .case-module');
	const $headerWrapper = $headerEl.find('> .case-module-wrapper');
	const $headerWrapperTop = $headerWrapper.offset().top - $zigzagTop;
	const $sections = $zigzag.find('section');
	const $popArea = $('.pop-area');
	const $popTop = $popArea.offset().top + $popArea.position().top + $zigzagTop;
	const $mainPaddingTop = parseInt($('main').css('padding-top'));

	// Line settings
	const lineStartY = $headerWrapperTop + lineStartFactor* $headerWrapper.height();
	let lineDir = $zigzag.data('linestart');
	let mLeft = 0;
	lineDir || (lineDir = 'right');
	let lineLengthZigzag = 0;
	const lineEndDistance = 12;	// Distance to image at line's end
	const linepath = [];
	const lines = [];
	const texts = [];

	/* Draw lines around sections */

	let hLineOffsetLast = 0;
	$sections.each((i, section) => {
		if (!i) return;	// Skip first section
		const $section = $(section);
		const $moduleWrapper = $section.find('.module-wrapper');
		const $h3 = $section.find('h3');
		const borderWidth = 1;	// from CSS
		let hLineOffset = 0;
		let sHeight = $section.height();
		let sWidth;
		if ($moduleWrapper.length) {
			sWidth = $moduleWrapper.innerWidth();
			let $contentWrapper = $moduleWrapper.find('.case-module-content');
			if ($contentWrapper.length)
				hLineOffset = (sWidth - $contentWrapper.width())/4 - baseWidth/2 - borderWidth;
		} else {
			sWidth = $section.width();
		}
		const $width = sWidth;

		// Define lines
		if (i === 1) {
			// Start line
			sWidth /= 2;
			sWidth -= hLineOffset;
			const sTop = $section.offset().top - $zigzagTop - baseWidth/2;
			linepath.push('M ' + $zigzagWidth/2 + ',' + baseWidth/2);
			mLeft += $zigzagWidth/2;
			linepath.push('v' + (sTop - lineStartY + sHeight/2));
			linepath.push('h' + lineDirChange(sWidth));
			linepath.push('v' + sHeight/2);
			lineLengthZigzag += sTop - lineStartY + sWidth;
		} else {
			if (i < $sections.length - 2) {
				// Normal line: horizontal, then down
				sWidth -= hLineOffset + hLineOffsetLast;
				linepath.push('h' + lineDirChange(sWidth));
				linepath.push('v' + sHeight);
				lineLengthZigzag += sWidth;
			} else if (i < $sections.length - 1) {
				// Last line (pop area): down
				sWidth /= 2;
				sWidth -= hLineOffsetLast;
				linepath.push('h' + lineDirChange(sWidth));
				lineLengthZigzag += sWidth - 1.5*baseWidth;
				const $img = $section.find('img');
				if (!$img.length) {
					console.warn('No image in result area!');
					sHeight /= 2;
				} else {
					sHeight = $img.offset().top - $section.offset().top - baseWidth/2 - lineEndDistance;
				}
				linepath.push('v' + sHeight);
			}
		}
		hLineOffsetLast = hLineOffset;

		// Convert headings to SVG
		if ($h3.length && i < $sections.length - 1) {	// Except last section
			const textobj = {
				text: $h3.text(),
				size: {width: $h3.width(), height: $h3.height()},
				dir: lineDir
			};
			$h3.hide();
			const posY = $section.offset().top - $mainPaddingTop + 49;
			let posXFactor, posXMargin;
			if (lineDir === 'left') {
				posXFactor = 1 - headingPosFactor;
				posXMargin = mLeft - sWidth;
			} else {
				posXFactor = headingPosFactor;
				posXMargin = mLeft;
			}
			if (i < $sections.length - 2) {	// Normal section
				textobj.pos = {x: posXFactor * sWidth + posXMargin, y: posY};
				textobj.start = lineLengthZigzag - headingPosFactor * sWidth;
			} else {	// Next to last section
				textobj.pos = {x: posXMargin + sWidth/2, y: posY};
				textobj.start = lineLengthZigzag - sWidth/2;
			}
			texts.push(drawText(textobj));
		}
		lineLengthZigzag += sHeight;

		// Place social media icons
		const $toCenter = $section.find('> .center-vertical-line');
		if ($toCenter.length) {
			const pos = {
				top: (sHeight - $toCenter.height())/2,
				left: mLeft - $toCenter.width()/2
			};
			if (lineDir === 'left') pos.left += sWidth;
			$toCenter.css(pos);
		}
	});

	// Create SVG line
	lines.push(drawPath(linepath.join(' '), [$zigzagWidth, $zigzagHeight - lineStartY], {x: 0, y: lineStartY}));
	const allLines = lines.map(line => line.lineLength);
	allLines.total = allLines.reduce((a, b) => a + b);


	/* Scrolling */
	const _pop = function($el) {
		if (!$el.hasClass(popClass))
			$el.addClass(popClass);
	};
	const _unpop = function($el) {
		if (popupPermanent) return;
		if ($el.hasClass(popClass))
			$el.removeClass(popClass);
	};

	const animate = ev => {
		const scrollPos = $(window).scrollTop();
		const scrollPosLower = scrollPos + $winHeight;
		let scrollPercent = scrollPos / ($docHeight - $winHeight),
			zickzackPercent = (scrollPos - $zigzagTop) / ($zigzagHeight + $zigzagTop - $winHeight/scrollFactor);
		if (scrollPercent > 1) scrollPercent = 1;
		if (scrollPercent < 0) scrollPercent = 0;
		if (zickzackPercent > 1) zickzackPercent = 1;
		if (zickzackPercent < 0) zickzackPercent = 0;
		const linePos = allLines.total * zickzackPercent;

		// Fill line and headings
		fillLine(zickzackPercent, linePos);
		fillHeadings(linePos);

		// Pop-up
		+function() {
			const $pops = $('.pop');
			if (!$pops.length) return;
			const upperTresholdPx = $popTop + popUpper*$winHeight;
			const upperTreshold = upperTresholdPx/$docHeight;
			const lowerTresholdPx = $popTop + popLower*$winHeight;
			const lowerTreshold = lowerTresholdPx/$docHeight;

			let popping = true;
			// Trigger only if line is completed?
			if (popupAfterLineCompleted) {
				if (zickzackPercent < 1) popping = false;
			}
			if (scrollPosLower < upperTresholdPx) popping = false;
			//if (scrollPercent < upperTreshold) popping = false;
			if (!popping) {
				_unpop($pops);
				return;
			}
			const popDistance = (lowerTresholdPx - upperTresholdPx) / ($pops.length - 1);
			for (let i = 0, scr = upperTresholdPx; i < $pops.length; i++) {
				let $pop = $pops.eq(i);
				if (scr <= scrollPosLower)
					_pop($pop);
				else
					_unpop($pop);
				if (popupOneByOne) scr += popDistance;
			}
		}();
	}

	animate();
	$(window).scroll(animate);

	/* SVG functions */

	function drawPath(path, size, pos) {
		// Creates SVG from path
		const drawing = SVG('zigzag').size(...size);
		drawing.path(path).addClass('base');
		drawing.path(path).addClass('fill');
		drawing.lineLength = drawing.last().node.getTotalLength();
		drawing.select('.base').members[0].attr({
			'stroke-linejoin': 'round',
			'stroke-linecap': 'round'
		});
		drawing.select('.fill').members[0].attr({
			'stroke-dashoffset': fillWidth/2,
			'stroke-dasharray': '0, ' + (drawing.lineLength + fillWidth).toString(),
			'stroke-linejoin': 'round',
			'stroke-linecap': 'round'
		});
		drawing.addClass('zigzagline');
		drawing.style({
			left: pos.x + 'px',
			top: pos.y + 'px'
		});
		return drawing;
	}

/*
	function drawLine(length, dir, pos) {
		// Creates SVG path from length and direction - UNUSED
		const start = [0, baseWidth/2];
		const size = [Math.abs(length), baseWidth];
		if (length < 0) start[0] += length * -1;
		if (dir === 'v') {
			start.reverse();
			size.reverse();
		}
		const path = 'M ' + start + ' ' + dir + length;
		return drawPath(path, size, pos);
	}
*/

	function drawText(opt) {
		// Creates SVG text
		if (!opt) return;
		if (!opt.text) return;
		const fullWidth = opt.size.width + 2*headingPaddingX;
		const fullHeight = opt.size.height + 2*headingPaddingY;
		const textDrawing = SVG('zigzag')
		.size(fullWidth, fullHeight)
		.style({
			left: opt.pos.x - fullWidth/2 + 'px',
			top: opt.pos.y - headerPaddingY - fullHeight/2 + 'px'
		});
		textDrawing.addClass('svgHeading');
		const colors = [baseCol, fillCol];
		let startval = 0;
		if (opt.dir === 'right') {
			colors.reverse();
			startval = 1;
		}
		const gradient = textDrawing.gradient('linear', stop => {
			stop.at(0, colors[0]),
			stop.at(startval, colors[0]),
			stop.at(startval, colors[1]),
			stop.at(1, colors[1])
		});
		textDrawing.text(opt.text).fill(gradient).attr({
			'text-anchor': 'middle',
			x: opt.size.width/2,
			y: headingPaddingY * -1
		});
		textDrawing.scrollBounds = {
			'start': opt.start - fullWidth/2,
			'end': opt.start + fullWidth/2,
			'width': fullWidth,
			'dir': opt.dir
		};
		return textDrawing;
	}

	function lineDirChange(length) {
		// Sets lineDir
		if (lineDir === 'left') {
			length *= -1;
			lineDir = 'right';
		} else {
			lineDir = 'left';
		}
		mLeft += length;
		return length;
	}

	function fillLine(percent, linePos) {
		// On scroll: fills line according to percentage
		let limit, rest;
		for (limit = 0, rest = linePos; limit < allLines.length; limit++) {
			if (allLines[limit] > rest) break;
			rest -= allLines[limit];
		}
		lines.forEach((el, i) => {
			let paintLength = 0;
			if (i < limit) paintLength = el.lineLength;
			else if (i > limit) paintLength = 0;
			else paintLength = rest;
			el.select('.fill').members[0].attr({
				'stroke-dasharray': (paintLength + percent * fillWidth) + ', ' + (el.lineLength - linePos + fillWidth)});
		});
	}

	function fillHeadings(linePos) {
		// On scroll: fills headings according to line fill state
		texts.forEach(t => {
			if (!t) return;
			let percent = 0;
			if (linePos < t.scrollBounds.start) {
				// Nothing to do
			} else if (linePos > t.scrollBounds.end) {
				percent = 1;
			} else {
				percent = (linePos - t.scrollBounds.start) / t.scrollBounds.width;
			}
			if (t.scrollBounds.dir === 'right')
				percent = 1 - percent;
			[1,2].forEach(i => t.select('stop').members[i].attr({offset: percent}));
		});
	}
};

$(zigzag);
// Reset line dimensions after images are loaded and on resize
$(window)
.on('load', zigzag)
.on('resize', zigzag);