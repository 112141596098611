(function() {

	function slide() {

		const slidesToShow = [
			[2, 2.8, 3  , 3.3, 4.8, 5.3, 6.5],
			[3, 3.5, 4.2, 5.2, 8.8, 9.2, 9.7]
		];

		$('.slider-wrapper').each((i, el) => {
			const $el = $(el);
			const $carousel = $el.find('.owl-carousel');
			const $prev = $el.find('.scroll-prev');
			const $next = $el.find('.scroll-next');
			const countSlides = $carousel.find('>*').length;

			// Read data attributes
			// Supported: data-slidestoshow, data-speed, data-startpos
			const slidesToShowPreSetup = parseFloat($carousel.data('slidestoshow')) || slidesToShow[i][4];
			const speed = parseInt($carousel.data('speed')) || 1300;
			const startPos = parseInt($carousel.data('startpos')) || Math.floor((countSlides - slidesToShowPreSetup) /2);
			const loop = $carousel.data('loop')? true : false;

			const responsiveSettings = {
				// float values are buggy
				0:    {items: Math.round(slidesToShow[i][0])},
				480:  {items: Math.round(slidesToShow[i][1])},
				769:  {items: Math.round(slidesToShow[i][2])},
				992:  {items: Math.round(slidesToShow[i][3])},
				1200: {items: Math.round(slidesToShowPreSetup)},
				1441: {items: Math.round(slidesToShow[i][5])},
				1681: {items: Math.round(slidesToShow[i][6])},
			};

			const $slider = $carousel.owlCarousel({
				responsive: responsiveSettings,
				loop:	loop,	// might trigger Rangeerror (with responsive)
				margin:	10,
				center:	false,
				lazyLoad: true,
				dots: false,
				navRewind: false,
				smartSpeed: speed,
				mouseDrag: false,
				startPosition: startPos
			});

			let scroll;

			const stop = () => clearInterval(scroll);

			const rw = () => {
				stop();
				$slider.trigger('prev.owl.carousel');
				scroll = setInterval(() => {
					$slider.trigger('prev.owl.carousel');
				}, speed);
			};

			const fw = () => {
				stop();
				$slider.trigger('next.owl.carousel');
				scroll = setInterval(() => {
					$slider.trigger('next.owl.carousel');
				}, speed);
			}

			$prev.mouseenter(rw).mouseleave(stop);
			$next.mouseenter(fw).mouseleave(stop);

			$slider.on('changed.owl.carousel', ev => {
				const indexLast = ev.item.index + ev.page.size;
				if (ev.item.index <= 0) {
					stop();
					$prev.hide();
				} else {
					$prev.show();
				}
				if (indexLast >= ev.item.count) {
					stop();
					$next.hide();
					if (indexLast > ev.item.count) {
						// otherwise: jumps back to start, buggy
						stop();
						$slider.trigger('to.owl.carousel', [ev.item.count - ev.page.size, speed, true]);
					}
				} else {
					$next.show();
				}
			});
		});
	}

	jQuery(document).ready(slide);
})();
