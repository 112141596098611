(function() {
	function easteregg () {
		var $easteregg = '';
		var $logo = $(".logo");

		/*
			D = 68
			O = 79
			M = 77
			E = 69
		*/

		$(document).keydown(function(event) {
			$easteregg += event.keyCode;
			if ($easteregg.indexOf("68797769") != '-1') {
				$("html, body").animate({
					scrollTop: 0
				}, 600);
				$logo.toggleClass("easteregg");
				$easteregg = '';
			}
		});
	}

	jQuery(document).ready(easteregg);
})();
