(function() {

    
       
    function validateForm () {
        
        $('#contact_form').bootstrapValidator({
        // To use feedback icons, ensure that you use Bootstrap v3.1.0 or later
        feedbackIcons: {
            valid: 'fa fa-check',
            invalid: 'fa fa-exclamation',
            validating: 'fa fa-refresh'
        },
        fields: {
            first_name: {
                validators: {
                        stringLength: {
                        min: 2,
                    },
                        notEmpty: {
                        message: 'Bitte geben Sie einen Vornamen ein.'
                    }
                }
            },
             last_name: {
                validators: {
                     stringLength: {
                        min: 2,
                    },
                    notEmpty: {
                        message: 'Bitte geben Sie einen Nachname ein.'
                    }
                }
            },
            email: {
                validators: {
                    notEmpty: {
                        message: 'Bitte geben Sie eine E-Mail-Adresse ein.'
                    },
                    emailAddress: {
                        message: 'Bitte geben Sie eine korrekte E-Mail-Adresse ein.'
                    }
                }
            },
            phone: {
                validators: {
                    notEmpty: {
                        message: 'Bitte geben Sie eine Telefonnummer ein.'
                    },
                    digits: {
                        message: 'Bitte geben Sie nur Zahlen ein.'
                    },
                    stringLength: {
                        min: 6,
                        message:'Ihre Telefonnummer ist zu kurz.'
                    },
                }
            },
            comment: {
                validators: {
                      stringLength: {
                        min: 10,
                        max: 300,
                        message:'Ihre Nachricht muss mindestens 10 Zeichen und maximal 300 Zeichen lang ein.'
                    },
                    notEmpty: {
                        message: 'Bitte geben Sie eine Nachricht ein.'
                    }
                    }
                }
            }
        })
        .on('success.form.bv', function(e) {
            $('#success_message').slideDown({ opacity: "show" }, "slow") // Do something ...
                $('#contact_form').data('bootstrapValidator').resetForm();

            // Prevent form submission
            e.preventDefault();

            // Get the form instance
            var $form = $(e.target);

            // Get the BootstrapValidator instance
            var bv = $form.data('bootstrapValidator');

            // Use Ajax to submit form data
            $.post($form.attr('action'), $form.serialize(), function(result) {
                console.log(result);
            }, 'json');
        });  
    }
    jQuery(document).ready(validateForm);
    
    
    
}) (); 
 
