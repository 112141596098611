(function() {

	function clients () {
		var $clients = $(".clients-homepage-list-wrapper>ul>li");
		
		$clients.each((i, el) => {
			let $el = $(el);
			
			$el.click(ev => {
				
				const $allOthers = $clients.not($el);
								
				if($el.hasClass('is-active')){
					
					$el.removeClass('is-active');
					$allOthers.removeClass('is-in-active');
					
				}else if($el.hasClass('is-in-active')){

					$clients.removeClass('is-in-active is-active');
					
					setTimeout(function(){
						$allOthers.addClass('is-in-active');
						$el.addClass('is-active');
						
					},200);

					
				}else{
					
					$el.addClass('is-active');
					$allOthers.addClass('is-in-active');
				
				}		
			})
		});
	}
	jQuery(document).ready(clients);
})();