// Startpage animation

+function() {
	'use strict';
	const debug = 0,
		circleTexts = {},
		$stage = $('#stage'),
		$sections = $('section.carousel'),
		$circleContainer = $('#circleContainer');
	if ($stage.length === 0) return;
	$('body').addClass('circles no-scrollbar');

	const circling = () => {
		if (debug)
			console.info('circling', new Date().toLocaleString());
		const circlesIdExtension = 'Circles',
			stageOffset = $circleContainer.offset(),
			lls = []	// tweak circle position (debug only)
		;

		// jQuery extension: find center of element and save it
		$.fn.extend({
			getCenter: function(update = true) {
				for (let i = 0; i < this.length; i++) {
					if (!update)
						if (this[i].center) return;
					const rect = this[i].getBoundingClientRect();
					const yDiff = window.pageYOffset - stageOffset.top;
					this[i].center = {
						x: rect.left + rect.width / 2 + window.pageXOffset - stageOffset.left,
						y: rect.top + rect.height / 2 + yDiff
					};
					this[i].bottom = rect.bottom + yDiff;
				}
				return this;
			}
		});

		// Remove highlight, lines and description
		const removeHighlight = ($el, $circlesDiv, $lines, $descrs) => {
			$el.highlight = false;
			$circlesDiv.removeClass('highlight');
			if (debug < 2) {
				$lines.hide();
				$descrs.hide();
			}
		};

		// Animate circle descriptions
		const toAnimate = ($el, $descrs, $circles) => {
			if (!$el.highlight) {
				if (debug > 1) console.info('Stop JS animation');
				return;
			}
			$circles.getCenter();
			$descrs.each((i, el) => {
				const hWidth = $(el).width()/2;
				$(el).css({
					left: $circles[i].center.x - hWidth,
					top: $circles[i].bottom,
				});
			});
			requestAnimationFrame(() => toAnimate($el, $descrs, $circles));
		};

		// Calculate line length (for circle placement)
		const calcDist = (x1, x2, y1, y2) => Math.sqrt((x2 -= x1) * x2 + (y2 -= y1) * y2);

		// Copy and prepare circle texts
		$circleContainer.find('> div.circleArea').each((i, div) => {
			const $div = $(div);
			const circleGroup = $div.attr('id').slice(0, circlesIdExtension.length * -1);
			if (circleTexts[circleGroup]) return;	// after resizing
			circleTexts[circleGroup] = [];
			$div.find('span').each((j, span) => {
				const $span = $(span);
				circleTexts[circleGroup].push($span.text());
				$span.empty();
			});
		});

		// Draw and animate lines
		$sections.each((i, el) => {
			if (!hasMouse) return;
			const circleGroup = el.id;
			const $el = $(el);
			const $circlesDiv = $('#' + circleGroup + circlesIdExtension);
			$circlesDiv.find('.lines').remove();
			const $circles = $circlesDiv.find('span');
			$circlesDiv.append('<div id="' + circleGroup + 'Lines" class="lines"></div>');
			const $lines = $circlesDiv.find('#' + circleGroup + 'Lines');
			$lines.hide();
			$circles.getCenter();
			const draw = SVG(circleGroup + 'Lines').size($lines.width(), $lines.height());
			$el.getCenter();
			$circlesDiv.css('transform-origin', $el[0].center.x + 'px ' + $el[0].center.y + 'px');
			$('#' + circleGroup + 'Descrs').remove();
			const $descrs = $('<div id="' + circleGroup + 'Descrs" class="descriptions"></div>');
			$circleContainer.append($descrs);
			$el.highlight = false;

			// Create lines and descriptions
			for (let j = 0; j < $circles.length; j++) {
				$circles.eq(j).getCenter();
				const l = draw.line($el[0].center.x, $el[0].center.y, $circles[j].center.x, $circles[j].center.y);
				if (debug) {
					const lineLen = calcDist(l.node.x1.baseVal.value, l.node.x2.baseVal.value, l.node.y1.baseVal.value, l.node.y2.baseVal.value);
					const lineId = 'l' + (i+1) + (j+1);
					lls.push({id: lineId, val: lineLen, txt: circleTexts[circleGroup][j]});
				}
				const $circleDescr = $('<span class="descr">' + circleTexts[circleGroup][j] + '</span>');
				$descrs.append($circleDescr);
			}

			// Highlighting
			$el
				.mouseenter(() => {
					// draw lines and descriptions
					if (debug > 1) console.info('Start JS animation');
  				$el.highlight = true;
					$circlesDiv.addClass('highlight');
					$lines.show();
					$descrs.show();
  				toAnimate($el, $descrs.find('.descr'), $circles);
					// clean up other lines (shouldn't happen, but does)
					$sections.each((i, el) => {
						if (el.id === circleGroup) return;
						const $_cd = $('#' + el.id + circlesIdExtension);
						const $_l = $_cd.find('#' + el.id + 'Lines');
						const $_desc = $circleContainer.find('#' + el.id + 'Descrs');
						removeHighlight($(el), $_cd, $_l, $_desc);
					});
				})
				.mouseleave(() => {
					removeHighlight($el, $circlesDiv, $lines, $descrs)
				})
			;
		});

		if (debug) {
			lls.sort((a, b) => a.val - b.val);
			console.info(lls);
		}
	};
	circling();

	// Arrow animation and click event
	const $body = $('html, body');
	const winHeight = $(window).height();
	const $arrow = $('.home + .arrow');
	$arrow
		.mouseenter(ev => {
			const $target = $(ev.target);
			if ($target.hasClass('clicked')) return;
			$target.removeClass('animated');
		})
		.mouseleave(ev => {
			const $target = $(ev.target);
			if ($target.hasClass('clicked')) return;
			$target.addClass('animated');
		})
		.click(ev => {
			$(ev.target)
				.removeClass('animated')
				.addClass('clicked');
			$body
				.stop()
				.animate({scrollTop: winHeight + 'px'}, 750, 'swing');
		})
	;

	// Remove arrow after scrolling
	const checkArrow = ev => {
		if ($(window).scrollTop() < winHeight) return;
		//$arrow.removeClass('animated');
		$arrow.hide();
		$(window).off('scroll', checkArrow);
	}
	$(window).on('scroll', checkArrow);

	// Start/restart circles

	const restart = ev => {	// clean up, start again
		if (debug) console.info(ev.type);
		$('#lines').remove();
		$sections.each((i, el) => $(el).getCenter());
		$('.circleArea').removeClass('spinning');
		circling();
		$('.circleArea').addClass('spinning');
	};

	$(window)
		.on('load', restart)
		.on('resize', restart)
		.on('mouseDetect', restart)
	;
}();
